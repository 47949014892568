<template>
    <div>
        <b-row>
            <b-col cols="12">
                <div class="d-flex justify-content-between align-items-center mb-1">
                    <h6 class="mb-0">Roles & Candidates</h6>
                    <b-button v-b-toggle.cu-role
                              data-cy="addRoleButton"
                              variant="primary" size="sm"
                              @click="clearRoleModal()"
                    >
                        <feather-icon icon="PlusIcon"/> Add Role
                    </b-button>

                    <b-sidebar
                        sidebar-class="sidebar-lg"
                        id="cu-role"
                        right
                        backdrop
                        shadow
                        width="450px"
                        bg-variant="white"
                    >
                        <template #header="{ hide }">
                          <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
                            <h5 class="mb-0">Add / Edit Role</h5>
                            <div>
                              <feather-icon
                                  class="ml-1 cursor-pointer"
                                  icon="XIcon"
                                  size="16"
                                  @click="hide"
                              />
                            </div>
                          </div>
                        </template>
                        <modal-edit-role></modal-edit-role>
                    </b-sidebar>
                </div>
                <!-- LEADS -->
                <b-card no-body class="px-2 py-0">
                    <b-row class="py-1">
                        <span class="mx-1"> <feather-icon icon="UserIcon" class="text-success" size="18" /></span>
                        <h5 class="mb-0">LEADS ({{leadRoles.length}})</h5>
                    </b-row>
                    <div v-for="(oneRole, index) in leadRoles" :key="index" class="">
                        <role-row :role="oneRole"
                                  :shortlisted-actors="shortlistedActors"
                                  :active-production="activeProduction"
                        >
                        </role-row>
                    </div>
                </b-card>

                <!-- Supporting -->
                <b-card no-body class="px-2 py-0">
                    <b-row class="py-1">
                        <span class="mx-1"> <feather-icon icon="UserIcon" class="text-success" size="18" /></span>
                        <h5 class="mb-0">SUPPORTING ({{supportRoles.length}})</h5>
                    </b-row>
                    <div v-for="(oneRole, index) in supportRoles" :key="index">
                        <role-row :role="oneRole"
                                  :shortlisted-actors="shortlistedActors"
                                  :active-production="activeProduction"
                        >
                        </role-row>
                    </div>
                </b-card>

                <!-- DAY-PLAYERS -->
                <b-card no-body class="px-2 py-0">
                    <b-row class="py-1">
                        <span class="mx-1"> <feather-icon icon="UserIcon" class="text-success" size="18" /></span>
                        <h5 class="mb-0">DAY PLAYERS ({{dayRoles.length}})</h5>
                    </b-row>
                    <div v-for="(oneRole, index) in dayRoles" :key="index">
                        <role-row :role="oneRole" :shortlisted-actors="shortlistedActors"
                                  :active-production="activeProduction"
                        >
                        </role-row>
                    </div>
                </b-card>
            </b-col>
        </b-row>

        <b-sidebar
                id="cu-viewProfile"
                right
                backdrop
                shadow
                width="450px"
                sidebar-class="sidebar-lg"
                bg-variant="white"
        >
            <template #header="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
                    <div>
                        <feather-icon
                                class="ml-1 cursor-pointer"
                                icon="XIcon"
                                size="16"
                                @click="hide"
                        />
                    </div>
                </div>
            </template>
            <modal-view-profile></modal-view-profile>
        </b-sidebar>

        <b-modal
            id="delete-role"
            ok-variant="danger"
            ok-title="Delete"
            cancel-title="Cancel"
            cancel-variant="outline-secondary"
            modal-class="modal-danger"
            size="sm"
            button-size="sm"
            centered
            title="Delete Role"
            @ok="deleteRole()"
        >
            <div class="modal-body">
                <p>Are you sure you want to delete this role?</p>
                <p>All related Auditions, Invitations to Audition and attached materials will be <strong>permanently deleted!</strong></p>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        //BAvatar,
        BButton,
        //BBadge,
        VBTooltip,
        VBToggle,
        BSidebar,
        //BImg,
        BCard
    } from 'bootstrap-vue'
    //import QfSidebarContent from "../layouts/components/QfSidebarContent";
    import store from "@/store";
    import Parse from "parse";
    import moment from "moment";
    import ModalEditRole from "@/modals/ModalEditRole";
    import ModalViewProfile from "@/modals/ModalViewProfile";
    import RoleRow from "@/views/components/roles/RoleRow";
    import Api2 from "@/services/api2";
    import ToastificationContent from "@core/components/toastification/ToastificationContent";

    export default {
        components: {
            ModalEditRole,
            ModalViewProfile,
            //QfSidebarContent,
            BRow,
            BCol,
            //BAvatar,
            BButton,
            //BBadge,
            BSidebar,
            //BImg,
            BCard,
            RoleRow
        },
        directives: {
            'b-tooltip': VBTooltip,
            'b-toggle': VBToggle,
        },
        data() {
            return {
                profileId: null,
                activeProduction: {
                    attributes: {
                    }
                },
                icons: {
                    crown: require('@/assets/images/icons/crown.svg')
                },
            }
        },
        methods: {
            comapareFn(a,b){
              if(a.attributes.priority && b.attributes.priority){
                return a.attributes.priority - b.attributes.priority
              } else if(a.attributes.priority) {
                  return -1;
              } else if (b.attributes.priority) {
                  return 1;
              } else {
                  return a.attributes.name.localeCompare(b.attributes.name);
              }
            },
            deleteRole(){
                let self = this;
                let curUser = Parse.User.current();
                let payload = {
                    sessionToken: curUser.attributes.sessionToken,
                    roleId: this.roleToDelete.id,
                    prodId: this.activeProduction ? this.activeProduction.id : null,
                }
                Api2.sendData("deleteRole", payload).then(() => {
                    this.$bvModal.hide('delete-role');
                    store.commit('app/REMOVE_ROLE', this.roleToDelete);
                    store.commit('app/REFRESH_ROLES');
                    self.clearRoleModal();
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Role successfully deleted!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });
                }).catch((err) => {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'An error has occured while deleting this role!',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            text: err.message
                        },
                    });
                });
            },
            clearRoleModal() {
                this.$store.commit('app/SET_ROLE', {});
            },
            setRoleToEdit(oneRole) {
                let fullRoleObj = this.roles.find(role => role.id === oneRole.id);
                store.commit('app/SET_ROLE', fullRoleObj);
            },

            niceDate(parseDate) {
                return moment(parseDate).format("DD-MM-YYYY");
            },
            characterIllustration(oneRole){
                if(oneRole.attributes.characterIllustration){
                    return oneRole.attributes.characterIllustration._url;
                }else{
                    return this.defaultLogo('svg');
                }
            },
            openShortlised(shortlistAct){
                store.commit('app/SET_TALENT', shortlistAct);
            },
            applicantAvatar(talent){
                if(talent && talent.attributes.avatar){
                    return talent.attributes.avatar._url;
                }else{
                    return "";
                }
            },
            initials(talent){
                if(talent.attributes.firstName){
                    return talent.attributes.firstName.substring(0, 1) + talent.attributes.lastName.substring(0, 1);
                }
                else{
                    return "";
                }
            },
            getRoles(prodId){
                let ActingRole = Parse.Object.extend('ActingRole');
                let ProdQuery = new Parse.Query(ActingRole);

                const rolePointer = { "__type": "Pointer", "className": "Production", "objectId": prodId };
                ProdQuery.equalTo("production", rolePointer);
                ProdQuery.include("talentPick");
                ProdQuery.addAscending("roleType");
                ProdQuery.addAscending("name");
                ProdQuery.find()
                    .then(function(results){
                        if(results.length){
                            results.forEach(function(oneResult){
                               if(oneResult.attributes.shortlistedTalent){
                                   let userQuery = new Parse.Query(Parse.User);
                                   userQuery.containedIn("objectId", oneResult.attributes.shortlistedTalent);
                                   userQuery.include("relatedMeta");
                                   userQuery.find().then(function(users){
                                       store.commit('app/ADD_SHORTLISTED', {roleUsers: users, roleId: oneResult.id});
                                       store.commit('app/REFRESH_ROLES');
                                   });
                               }
                            });
                        }
                        store.commit('app/SET_ALL_ROLES', results);
                    })
                    .catch(function(err){
                        console.log("Parse error", err.message);
                    });
            },
            navToRole(roleObj){
                store.commit('app/SET_ROLE', roleObj);
                this.$router.push({path: '/production/'+this.activeProduction.id+'/roles/'+roleObj.id + '/details'});
            },
        },
        computed: {
            roleToDelete() {
                return store.getters['app/currentRole'];
            },
            shortlistedActors() {
                return store.getters['app/shortlistedTalents'];
            },
            roles(){
                return store.getters['app/allRoles'];
            },
            curProduction() {
                return store.getters['app/currentProduction'];
            },
            leadRoles() {
                let leads = [];
                this.roles.forEach(function(role){
                    if(role.attributes.roleType === "lead"){
                        leads.push(role);
                    }
                });

                leads.sort(this.comapareFn);
                return leads;
            },
            supportRoles() {
                let supports = [];
                this.roles.forEach(function(role){
                    if(role.attributes.roleType === "supporting"){
                        supports.push(role);
                    }
                });
                supports.sort(this.comapareFn);
                return supports;
            },
            dayRoles() {
                let dayPlayers = [];
                this.roles.forEach(function(role){
                    if(role.attributes.roleType === "day_player"){
                        dayPlayers.push(role);
                    }
                });
                dayPlayers.sort(this.comapareFn);
                return dayPlayers;
            },
        },
        watch: {
            curProduction: {
                immediate: true,
                handler(newVal){
                    this.activeProduction = newVal;
                    if(newVal){
                        this.getRoles(newVal.id);
                    }
                }
            },
        }
    }
</script>

<style>
    .roleImageSize {
        width: 50px;
        object-fit: contain;
        min-width: 50px;
        height: 70px;
    }

    .qf-collapse-header .card-header{
        display: flex;
        justify-content: initial;
        align-items: center;
    }

    .qf-title-badge {
        position: relative;
        display: inline-flex;
    }

    .qf-title-badge .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
        right: -25px;
        top: -8px;
        position: absolute;
        background: linear-gradient(12.04deg, #FC0434 0%, #FF8805 100%);
    }

.b-sidebar-header {
  background-color: #000013;
}
.qf-role-row {
    transition: all 0.5s ease-out;
}
.qf-role-row:hover {
    background: rgba(0,0,0,0.3);
}

.qf-role-priority {
    min-width: 20px;
    margin-right: 10px;
}

</style>
