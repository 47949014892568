<template>
    <div :class="extraClasses">
        <b-img :src="imageUrl" class="img-fluid"/>
        <div class="btn-play cursor-pointer" @click="showVideo()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="cursor-pointer feather feather-play"><polygon data-v-cb8df8c8="" points="5 3 19 12 5 21 5 3"></polygon></svg>
        </div>
        <span class="qf-disabled-notice" v-if="disabled">Processing...</span>
    </div>

</template>

<script>
    import {BImg,} from "bootstrap-vue";
    export default {
        name: "QfVideo",
        components: {
            BImg,
        },
        props:{
            avatarUrl: {
                type: String,
                default: ''
            },
            userNames: {
                type: String,
                default: ''
            },
            imageUrl: String,
            videoUrl: String,
            size: {
                type: String,
                default: 'medium'
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            initials(){
                let names = this.userNames.split(" ");
                return names[0].substring(0,1)+names[1].substring(0,1);
            },
            extraClasses(){
                let base = 'qf-video ';
                    base += 'size-'+ this.size;
                    if(this.disabled){
                        base += ' qf-disabled-vid';
                    }
                return base;
            }
        },
        methods: {
            
            showVideo() {
                const h = this.$createElement;
                // Using HTML string
                let titleVNode = '';
                let innerHTMLContent = '';
                if(this.avatarUrl){
                    innerHTMLContent = '<div class="d-flex align-items-center">' +
                        '<span class="b-avatar mr-1 rounded-circle" style="width: 48px; height: 48px;"><span class="b-avatar-img"><img src="'+this.avatarUrl+'" alt="avatar"></span></span>' +
                        '<div>' +
                        '<h5 class="mb-0">'+this.userNames+'</h5>' +
                        '</div>' +
                        '</div>'
                }else{
                    innerHTMLContent =
                        '<div class="d-flex align-items-center">' +
                            '<span class="b-avatar border-light mr-1 rounded-circle" style="width: 48px; height: 48px;">'+this.initials+'</span>' +
                            '<div>' +
                                '<h5 class="mb-0">'+this.userNames+'</h5>' +
                            '</div>' +
                        '</div>'
                }
                if(this.avatarUrl || this.userNames){
                    titleVNode = h('div', {
                        domProps: {
                            innerHTML: innerHTMLContent
                        }
                    });
                }
                
                // More complex structure
                const messageVNode = h('div', {
                    domProps: {
                        innerHTML:'<video controls="" autoplay controlsList="nodownload"><source src="'+ this.videoUrl +'" type="video/mp4">Your browser does not support HTML video.</video>'
                    }
                });
                // We must pass the generated VNodes as arrays
                this.$bvModal.msgBoxOk([messageVNode], {
                    title: [titleVNode],
                    size: 'lg',
                    centered: true,
                    hideHeaderClose: false,
                    okDisabled: true,
                    footerClass: 'hidden',
                    bodyClass: 'bbr p-0',
                })
            },
        }
    }
</script>

<style scoped>
    .qf-video{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 0;
        padding-top: 56.25%;
    }
    .qf-video img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    .qf-video .btn-play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #E85226;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .qf-video .btn-play svg {
        padding-left: 12%;
        width: 80%;
    }

    .qf-video.qf-disabled-vid{
        opacity: 0.5;
    }
    .qf-video.qf-disabled-vid .btn-play {
        background: #999999;
        pointer-events: none;
    }
    span.qf-disabled-notice {
        position: absolute;
        top: 20px;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        color: white;
    }


    .qf-video.size-medium {
        border-radius: 0.5rem;
    }

    .qf-video.size-large {
        border-radius: 2rem;
    }

    .qf-video.size-small {
        border-radius: 0.25rem;
    }
    .qf-video.size-large .btn-play {
        width: 70px;
        height: 70px;
    }
    .qf-video.size-small .btn-play {
        width: 40px;
        height: 40px;
    }
</style>
