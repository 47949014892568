<template>
  <div>
    <roles></roles>
  </div>
</template>

<script>
import {
  VBToggle,
  // BAvatarGroup,
  // BAvatar,
} from 'bootstrap-vue';
import store from '@/store';
import Roles from '@/views/Roles';
// import ModalAddEditShootingLocation from "@/modals/ModalAddEditShootingLocation";
import Parse from 'parse';
import moment from 'moment';

export default {
  components: {
    Roles,
    // BAvatarGroup,
    // BAvatar,
    // ModalAddEditShootingLocation,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      activeProduction: {
        attributes: {
          name: '',
        },
      },
      isExactActive: true,
    };
  },
  methods: {
    prodCoverUrl(production) {
      let productionImg = this.defaultLogo('png');
      if (production.attributes.coverImage) {
        productionImg = production.attributes.coverImage._url;
      }
      return productionImg;
    },
    mapLink(lat, long) {
      return (
        'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + long
      );
    },
    getSootingLocations(roleId) {
      let sootingLocation = Parse.Object.extend('ShootingLocation');
      let LocationQuery = new Parse.Query(sootingLocation);

      const rolePointer = {"__type": "Pointer", "className": "ActingRole", "objectId": roleId};

      LocationQuery.equalTo('relatedRole', rolePointer);
      LocationQuery.find()
        .then(function(results) {
          store.commit('app/SET_LOCATIONS', results);
          //console.log("Processed locations results: ", results);
        })
        .catch(function(err) {
          console.log('Parse error', err.message);
        });
    },
    niceDate(parseDate) {
      return moment(parseDate).format('DD-MM-YYYY');
    },
  },
  computed: {
    curProduction() {
      //let self = this;
      return store.getters['app/currentProduction'];
    },
    curProductionCover() {
      if (this.activeProduction) {
        return this.prodCoverUrl(this.activeProduction);
      } else {
        return this.defaultLogo('png');
      }
    },
    curProductionName() {
      if (this.activeProduction) {
        return this.activeProduction.attributes.name;
      } else {
        return '';
      }
    },
    curProductionDesc() {
      if (this.activeProduction) {
        return this.activeProduction.attributes.synopsis;
      } else {
        return '';
      }
    },
    shootingLocations() {
      return store.getters['app/shootingLocations'];
    },
  },
  mounted() {
    store.commit('app/CLEAR_ROLE');
    this.getSootingLocations(this.roleId);
  },
  watch: {
    curProduction: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          let prodQuery = new Parse.Query(Parse.Object.extend('Production'));
          prodQuery.get(this.$route.params.prodId).then(function(theProduction){
              store.commit('app/SET_PRODUCTION', theProduction);
            });
        }
        this.activeProduction = newVal;
      },
    },
  },
};
</script>

<style>
hr.qf-separator {
  width: 100%;
  height: 1px;
  display: block;
  background: rgba(255, 255, 255, 0.4);
}

.b-sidebar-header {
  background-color: #000013;
}

.shooting-location,
.qf-sides {
  padding: 0.5rem 0;
}
.shooting-location + .shooting-location,
.qf-sides + .qf-sides {
  border-top: 1px solid #3b4253;
}
.shooting-location svg {
  margin-top: 3px;
}
</style>
