import axios from 'axios';
import store from "@/store";

axios.defaults.withCredentials = true;

export default {
	sendData(action, payload, files = {}) {
		let formData = new FormData();
		formData.append('action', action);
		formData.append("data", JSON.stringify(payload));

		if(typeof files === 'object') {
			Object.keys(files).forEach(key => {
				formData.append(key, files[key]);
			});
		}

		store.commit('app/SET_UPLOAD_PROGRESS', {
			progressName: 'fileUpload',
			progress: 0
		});
		return axios.create({
			baseURL: `${process.env.VUE_APP_API_URL}`,
			withCredentials: false,
			headers: {
				'Accept': '*/*',
				'Content-Type': 'multipart/form-data',
				'Accept-Language' : 'en'
			}
		}).post("helpers/api.php", formData, {
			onUploadProgress: function (progressEvent) {
				let uploadProgress = progressEvent.loaded / progressEvent.total;
				let uploadPercentage = (uploadProgress * 100).toFixed(0);
				store.commit('app/SET_UPLOAD_PROGRESS', {
					progressName: 'fileUpload',
					progress: uploadPercentage
				});
			},
		})
	}
};
