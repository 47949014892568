<template>
  <div class="qf-header ion-padding-horizontal">
    <div class="qf-title">
      <div class="qf-label ion-text-uppercase">{{title}}</div>
    </div>
    <div class="qf-seeall" v-if="seeall !== false">
    <feather-icon icon="EditIcon" class="qf-details-icon" size="16" />
    </div>
  </div>
</template>

<script>
import {
} from 'bootstrap-vue'

export default {
  name: "qfSectionHeader",
  props:{
    title: String,
    seeall: Boolean,
  },
}
</script>

<style scoped>
.qf-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  margin-top: 29px;
  margin-bottom: 6px;
}
.qf-seeall {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 4px 0;
}
.qf-title {
  display: flex;
  align-items: center;
}
.qf-label {
  padding-right: 8px;
}
.qf-seeall ion-icon {
  font-size: 20px;
  margin-left: 2px;
}
</style>