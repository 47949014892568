<template>
    <div class="h-100 p-relative">
        <div class="px-2 pt-1 pb-2">
            <div class="qf-controls qf-controls-sidebar d-flex justify-content-end">
              <feather-icon icon="StarIcon" size="18" class="text-primary cursor-pointer"/>
            </div>
            <div class="qf-profile-avatar-box d-flex flex-column align-items-center">
                <b-avatar v-if="profileData.attributes.avatar"
                       :src="profileData.attributes.avatar._url"
                        class="qf-profile-avatar mb-1"
                      title="This talent has PRO subscription"
                      badge-offset="-0.5em"
                      badge-top
                      badge-variant=""
                      size="200px"
                >
                    <template #badge>
                        <b-badge v-if="profileData.attributes.subscriptionPlan && profileData.attributes.subscriptionPlan.attributes.productId == 'talentpremium'" class="modal-badge" variant="gradient-red">PRO</b-badge>
                    </template>
                </b-avatar>
                <b-avatar v-else size="200" class="qf-profile-avatar mb-1"></b-avatar>
            </div>
            <h2 class="justify-content-center d-flex">{{profileData.attributes.firstName}} {{profileData.attributes.lastName}}</h2>
            <div class="d-flex justify-content-center align-items-center mb-3">
                <a :href="`mailto:`+profileData.attributes.username" class="d-flex px-1 align-items-center">
                    <feather-icon icon="MailIcon" class="qf-details-icon" color="white" size="16"/>
                    <div class="rating">
                        <div>
                            <span>{{profileData.attributes.username }}</span>
                        </div>
                    </div>
                </a>
            </div>

            <div v-if="false" class="d-flex justify-content-center align-items-center mb-3">
                <div class="px-1 d-flex align-items-center">
                    <feather-icon icon="StarIcon" class="qf-details-icon" size="16"/>
                </div>
                <div class="d-flex px-1 align-items-center">
                    <feather-icon icon="StarIcon" class="qf-details-icon qf-star" size="16"/>
                    <div class="rating">
                        <div>
                            <span>  7,6</span>/10
                        </div>
                    </div>
                </div>
                <div class="px-1 d-flex align-items-center">
                    <feather-icon icon="InstagramIcon" class="qf-details-icon" size="16"/>
                </div>
            </div>
            <div no-body class="overflow-hidden">
                <div class="qf-profile-details mb-3">
                    <div class="d-flex align-items-center" v-if="talentMeta.age">
                        <feather-icon icon="UserIcon" class="qf-details-icon" size="16"/>
                        <div>
                            <p class="qf-details-title mb-0">AGE</p>
                            <p class="qf-details-data mb-0">{{talentMeta.age}}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center" v-if="talentMeta.height">
                        <feather-icon icon="MoveIcon" class="qf-details-icon" size="16"/>
                        <div>
                            <p class="qf-details-title mb-0">HEIGHT</p>
                            <p class="qf-details-data mb-0">{{talentMeta.height}} cm</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center" v-if="talentMeta.build">
                        <feather-icon icon="StarIcon" class="qf-details-icon" size="16"/>
                        <div>
                            <p class="qf-details-title mb-0">BUILD</p>
                            <p class="qf-details-data mb-0">{{talentMeta.build}}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center" v-if="talentMeta.gender">
                        <feather-icon icon="Maximize2Icon" class="qf-details-icon" size="16"/>
                        <div>
                            <p class="qf-details-title mb-0">GENDER</p>
                            <p class="qf-details-data mb-0">{{talentMeta.gender}}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center" v-if="talentMeta.ethnicity">
                        <feather-icon icon="SmileIcon" class="qf-details-icon" size="16"/>
                        <div>
                            <p class="qf-details-title mb-0">ETHNICITY</p>
                            <p class="qf-details-data mb-0">{{talentMeta.ethnicity}}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center" v-if="talentMeta.nationality">
                        <feather-icon icon="FlagIcon" class="qf-details-icon" size="16"/>
                        <div>
                            <p class="qf-details-title mb-0">NATIONALITY</p>
                            <p class="qf-details-data mb-0">{{talentMeta.nationality}}</p>
                        </div>
                    </div>
                </div>
                <qfSectionHeader title="Social Links"
                                 v-if="talentSocialMedia && (talentSocialMedia.facebook || talentSocialMedia.twitter || talentSocialMedia.instagram || talentSocialMedia.imdb || talentSocialMedia.tiktok)"
                ></qfSectionHeader>
                <div class="social-icons mb-1" v-if="talentSocialMedia && (talentSocialMedia.facebook || talentSocialMedia.twitter || talentSocialMedia.instagram || talentSocialMedia.imdb || talentSocialMedia.tiktok)">
                    <a class="mr-3" v-if="talentSocialMedia.facebook" target="_blank" :href="talentSocialMedia.facebook">
                        <feather-icon icon="FacebookIcon" class="qf-details-icon qf-svg-social" size="20"/>
                    </a>
                    <a class="mr-3" v-if="talentSocialMedia.twitter" target="_blank" :href="talentSocialMedia.twitter">
                        <feather-icon icon="TwitterIcon" class="qf-details-icon qf-svg-social" size="20"/>
                    </a>
                    <a class="mr-3" v-if="talentSocialMedia.instagram" target="_blank" :href="talentSocialMedia.instagram">
                        <feather-icon icon="InstagramIcon" class="qf-details-icon qf-svg-social" size="20"/>
                    </a>
                    <a class="mr-3" v-if="talentSocialMedia.imdb" target="_blank" :href="talentSocialMedia.imdb">
                        <b-img :src="qfSvgIcon('imdb')" class="qf-details-icon qf-svg-social" size="20"></b-img>
                    </a>
                    <a class="mr-3" v-if="talentSocialMedia.tiktok" target="_blank" :href="talentSocialMedia.tiktok">
                        <b-img :src="qfSvgIcon('tiktok-brands')" class="qf-details-icon qf-svg-social" size="20"></b-img>
                    </a>
                </div>
                <div v-if="false" class="qf-profile-social-box d-flex justify-content-between px-3">
                    <div>
                        <feather-icon icon="MessageSquareIcon" class="qf-social-profile-icon" size="16"/>
                        <p class="mb-0">327</p>
                    </div>
                    <div>
                        <feather-icon icon="HeartIcon" class="qf-social-profile-icon" size="16"/>
                        <p class="mb-0">587</p>
                    </div>
                    <div>
                        <feather-icon icon="ImageIcon" class="qf-social-profile-icon" size="16"/>
                        <p class="mb-0">25</p>
                    </div>
                    <div>
                        <feather-icon icon="PlayIcon" class="qf-social-profile-icon" size="16"/>
                        <p class="mb-0">12</p>
                    </div>
                    <div>
                        <feather-icon icon="AwardIcon" class="qf-social-profile-icon" size="16"/>
                        <p class="mb-0">2</p>
                    </div>
                </div>
            </div>
            <template v-if="talentMeta.primaryShowreel">
                <qfSectionHeader title="Showreel" class="mt-0"></qfSectionHeader>
                <qfVideo
                         :imageUrl="talentMeta.primaryShowreel.attributes.attachmentThumb._url"
                         :videoUrl="talentMeta.primaryShowreel.attributes.attachment._url"
                         :avatar-url="avatarLogo"
                         :user-names="profileData.attributes.firstName + ' ' + profileData.attributes.lastName"
                />
                <div class="qf-snap-scroll" v-if="false">
                    <div class="snap-item-wrapper">
                        <b-card class="qf-video">
                            <div class="video-icon">
                                <feather-icon icon="PlayIcon" class="qf-details-icon" size="16"/>
                            </div>
                            <b-img
                                src="https://www.pixelstalk.net/wp-content/uploads/2016/09/Art-Album-Cover-Wallpaper.jpeg"></b-img>
                        </b-card>
                    </div>
                    <div class="snap-item-wrapper">
                        <b-card class="qf-video">
                            <div class="video-icon">
                                <feather-icon icon="PlayIcon" class="qf-details-icon" size="16"/>
                            </div>
                            <b-img
                                src="https://www.pixelstalk.net/wp-content/uploads/2016/09/Art-Album-Cover-Wallpaper.jpeg"></b-img>
                        </b-card>
                    </div>
                    <div class="snap-item-wrapper">
                        <b-card class="qf-video p-0">
                            <div class="video-icon">
                                <feather-icon icon="PlayIcon" class="qf-details-icon" size="16"/>
                            </div>
                            <b-img
                                src="https://www.pixelstalk.net/wp-content/uploads/2016/09/Art-Album-Cover-Wallpaper.jpeg"></b-img>
                        </b-card>
                    </div>
                </div>
            </template>
            <qfSectionHeader title="Bio & skills"></qfSectionHeader>
            <b-card no-body class="overflow-hidden">
                <p class="mb-2" v-if="talentMeta.bio">{{talentMeta.bio}}</p>
                <template v-if="talentLanguages">
                    <h6 class="mb-0">Language</h6>
                    <div class="qf-language-wrapp mb-2">
                        <b-badge v-for="(oneLang, index) in talentLanguages"
                                 variant="primary"
                                 :key="index"
                                 class="mr-1"
                        >{{oneLang}}</b-badge>
                    </div>
                </template>
                <template v-if="talentSkills">
                    <h6 class="mb-0">Skills</h6>
                    <div class="qf-language-wrapp">
                        <b-badge v-for="(oneSkill, index) in talentSkills"
                                 variant="primary"
                                 :key="index"
                                 class="mr-1"
                        >{{oneSkill}}</b-badge>
                    </div>
                </template>
            </b-card>
            <template>
                <qfSectionHeader title="Gallery" v-if="galImages && galImages.length > 0" ></qfSectionHeader>
                <qfGallery v-b-modal.show-images @click="getGallery" v-if="galImages" :images="galImages" :userId="profileData.id"></qfGallery>
            </template>
            <qfSectionHeader title="Previous Acting Exp." v-if="actingExp.length > 0"></qfSectionHeader>
            <div class="qf-bluebox border-radius-8">
                <div class="bg-transparent qf-movie-list border-bottom mt-1" lines="none" v-for="(talentExperience, index) in actingExp" :key="index">
                    <div class="d-flex mb-1">
                        <b-img class="poster"
                               :src="getProdCover(talentExperience)"
                               slot="start"></b-img>
                        <div class="movie-info">
                            <div class="qf-movie-title"> {{ getProdName(talentExperience) }} </div>
                            <p class="qf-movie-details">{{ getProdSubtitle(talentExperience) }}</p>
                            <div class="qf-movie-title">As {{ getCharacterName(talentExperience) }}</div>
                            <p class="qf-movie-details">{{ getAward(talentExperience) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
                id="show-images"
                centered
                ok-only
        >
            <b-carousel
                    id="carousel-example-generic"
                    controls
                    indicators
                    :img-width="1024"
                    :img-height="600"
                    :interval="0"
                    
            >
                <b-carousel-slide v-for="(galImg, index) in galImages" :key="index" :img-src="galImg.attributes.attachment._url" />
            </b-carousel>
        </b-modal>
    </div>
</template>

<script>

	import {
        BAvatar,
		BImg,
		BCard,
		BBadge,
        BCarousel,
        BCarouselSlide,
        BModal,
        VBToggle
	} from 'bootstrap-vue'
	//import ToastificationContent from "@core/components/toastification/ToastificationContent";
	import Parse from 'parse';
	//simport store from "@/store";
	import qfSectionHeader from '../layouts/components/qfSectionHeader.vue';
	import qfGallery from '../layouts/components/qfGallery.vue';
	import qfVideo from '../layouts/components/QfVideo.vue';
	import store from "@/store";
	export default {
		name: "ModalEditCompany",
		components: {
            BAvatar,
			BImg,
			BCard,
			qfSectionHeader,
			qfGallery,
            qfVideo,
			BBadge,
            BCarousel,
            BCarouselSlide,
            BModal,
            
		},
        directives: {
            'b-toggle': VBToggle
        },
		props: {},
		data() {
			return {
				dicts: [],
                galImages: [],
                actingExp: {
                    attributes: {
                    }
                },
                
            }
		},

		mounted() {
            this.dicts = store.getters['dict/getRoleDicts']
		},
		computed: {
            avatarLogo(){
                if(this.profileData.attributes.avatar){
                    return this.profileData.attributes.avatar._url
                }
                else{
                    return "";
                }
            },
			profileData(){
				let activeTalent = store.getters['app/talentToPreview'];
				if (!activeTalent.id) {
					activeTalent = {
                        lastName: null,
						attributes: {
							relatedMeta: {
								attributes: {}
							},
                            relatedSocialMedia: {
                                attributes: {}
                            }
						}
					}
				}
				return activeTalent;
			},
			talentSocialMedia(){
                if(this.profileData.attributes.relatedSocialMedia){
                    return this.profileData.attributes.relatedSocialMedia.attributes;
                }
                else{
                    return null;
                }
                
			},
            talentMeta(){
                return this.profileData.attributes.relatedMeta.attributes;
            },
			talentLanguages(){
				let langs = this.mapData(this.talentMeta.language, 'languages');
				return langs;
            },
            talentSkills(){
				let skills = this.mapData(this.talentMeta.skills, 'skills');
				return skills;
            }
		},
		methods: {
            getGallery(){
                let self = this;

                let Media = Parse.Object.extend('Media');
                let mediaQuery = new Parse.Query(Media);

                const userPointer = { "__type": "Pointer", "className": "_User", "objectId": this.profileData.id };

                mediaQuery.equalTo('mediaType', 'talentGallery');
                mediaQuery.equalTo('relatedUser', userPointer);
                mediaQuery.ascending('galleryOrder');
                mediaQuery.find().then(function(images){
                    self.galImages = images;
                });
            },
            getProdCover(oneExp){
                if(oneExp.attributes && oneExp.attributes.productionCover){
                    return oneExp.attributes.productionCover._url;
                }
                else{
                    return this.$root.defaultLogo('svg');
                }
            },
            getProdName(oneExp){
                if(oneExp.attributes && oneExp.attributes.productionName){
                    return oneExp.attributes.productionName;
                }
                else{
                    return null;
                }
            },
            getProdSubtitle(oneExp){
                if(oneExp.attributes && oneExp.attributes.productionSubtitle){
                    return oneExp.attributes.productionSubtitle;
                }
                else{
                    return null;
                }
            },
            getCharacterName(oneExp){
                if(oneExp.attributes && oneExp.attributes.characterName){
                    return oneExp.attributes.characterName;
                }
                else{
                    return null;
                }
            },
            getAward(oneExp){
                if(oneExp.attributes && oneExp.attributes.award){
                    return oneExp.attributes.award;
                }
                else{
                    return null;
                }
            },
            getActingExperience(){
                let self = this;

                const ActingExperience = Parse.Object.extend("ActingExperience");
                const query = new Parse.Query(ActingExperience);

                const userPointer =
                    {
                        "__type": "Pointer",
                        "className": "_User",
                        "objectId": this.profileData.id
                    };
                query.equalTo("relatedUser", userPointer);

                query.find().then(function (results) {
                    self.actingExp = results;
                }).catch(function (exception) {
                    console.log(exception.message);
                });
            },
			dictLabel(input, dict, short=false){
                if(this.dicts[dict]){
                    let entry = this.dicts[dict].find(obj => {
                        return obj.value === input
                    });
                    if(entry){
                        if(short){
                            return entry.short;
                        }else{
                            return entry.text;
                        }
                    }else{
                        return '';
                    }
                }else {
                    return ''
                }
            },
            mapData(userDataList, param){
                let self = this;
                let processedData = [];
                if(userDataList){
                    userDataList.forEach(function(value){
                        processedData.push(self.dictLabel(value, param));
                    });
                }
                return processedData;
            },
        },
        watch: {
            profileData: {
                immediate: true,
                handler(newTalent){
                    if(newTalent) {
                        this.getActingExperience();
                        this.getGallery();
                    }
                }
            },
        },
	}
</script>

<style scoped>

    .qf-profile-avatar {
        width: 200px;
        height: 200px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%;
    }
    .b-avatar.qf-profile-avatar {
        border: 1px solid;
    }
    .qf-profile-details {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px 0;
    }

    .qf-details-title {
        font-size: 12px;
    }

    .qf-details-icon {
        margin-right: 5px;
    }

    .qf-details-data {
        color: #B4B7BD;
    }

    .qf-snap-scroll {
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        flex-flow: row nowrap;
        padding-left: 16px;
        display: flex;
    }

    .qf-snap-scroll::-webkit-scrollbar {
        display: none;
    }

    .qf-snap-scroll .qf-video {
        width: 192px;
        height: 108px;
        background: transparent;
        margin: 0;
        padding: 0 !important;
    }

    .qf-video .card-body {
        padding: 0 !important;
    }

    .qf-video .video-icon {
        position: absolute;
        background: rgba(255, 255, 255,0.3);
        border-radius: 10px;
        padding: 5px;
        backdrop-filter: blur(5px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .qf-video img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: inherit;
        object-position: inherit;
    }

    .qf-snap-scroll {
        padding-left: 0;
    }

    .qf-snap-scroll .snap-item-wrapper + .snap-item-wrapper {
        padding-left: 16px;
    }

    .snap-item-wrapper {
        scroll-snap-align: start;
    }

    .badge {
        font-size: 12px;
        font-weight: normal !important;
        padding: 5px 8px !important;
        margin: 8px 8px 0 0;
    }

    .movie-info {
        height: calc(100% - 20px);
        display: flex !important;
        flex-direction: column;
    }

    .poster {
        width: 96px;
        height: 100px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 20px;
    }

    .qf-movie-details {
        font-size: 10px;
        color: #B4B7BD;
    }

    .qf-profile-social-box {
        text-align: center;
    }

    .qf-social-profile-icon {
        margin-bottom: 8px;
    }

    .rating {
        font-size: 10px;
    }

    .rating > div > span {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
    }

    .qf-details-icon.qf-star {
        color: #ffc409;
        fill: #ffc409
    }

    .qf-details-icon.qf-svg-social{
        width: 26px;
        height: 26px;
        stroke: currentcolor;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .qf-controls-sidebar {
      position: absolute;
      right: 20px;
      top: 18px;
    }

</style>
