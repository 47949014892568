<template>
    <div class="h-100 p-relative">
        <div class="qf-search-filters">
            <div class=" p-2 m-0">

                <validation-observer :ref="`roleValidation`+roleId">

                    <b-form-group class="pb-1">
                        <div>
                            <label for="roleName" class="pt-1">Character name <sup class="text-danger">*</sup></label>
                            <validation-provider
                                v-slot="{ errors }"
                                name="character name"
                                rules="required"
                            >
                                <b-form-input name="roleName"
                                              id="roleName"
                                              v-model="form.name"
                                              placeholder="The name of the character for this role"
                                              :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>


                        <b-row class="pt-xs-2">
                            <b-col md="6">
                                <div class="pt-md-2 custom-control custom-switch">
                                    <input type="checkbox"
                                           class="custom-control-input"
                                           id="publicCasting"
                                           v-model="form.publicCasting">
                                    <label class="custom-control-label" for="publicCasting">Role is open for public casting</label>
                                </div>
                            </b-col>
                            <b-col md="6">
                                <div class="pt-md-2 custom-control custom-switch-secondary custom-switch">
                                    <input type="checkbox"
                                           class="custom-control-input"
                                           id="proCasting"
                                           v-model="form.proCasting">
                                    <label class="custom-control-label" for="proCasting">Restrict casting to <b-badge variant="gradient-red">PRO</b-badge> talent only</label>
                                </div>
                            </b-col>
                        </b-row>


                        <b-row>
                            <b-col md="5">
                                <label for="roleGender" class="pt-1">Gender</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-select
                                        id="roleGender"
                                        name="roleGender"
                                        v-model="form.gender"
                                        :options="formOptions.genders"
                                    >
                                        <template #first>
                                            <b-form-select-option value="null" disabled>Pick one
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                </div>
                            </b-col>

                            <b-col md="7">
                                <label for="roleType" class="pt-1">Role Type<sup class="text-danger">*</sup></label>
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="role type"
                                    rules="required"
                                >
                                    <b-form-select
                                        id="roleType"
                                        name="roleType"
                                        v-model="form.roleType"
                                        :options="formOptions.roleTypes"
                                    >
                                        <template #first>
                                            <b-form-select-option value="null" disabled>Pick one
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="5">
                                <label for="rolePriority" class="pt-1">Casting Priority</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-input name="rolePriority"
                                                  id="rolePriority"
                                                  v-model="form.priority"
                                                  type="number"
                                                  placeholder="Enter number"
                                    />
                                </div>
                            </b-col>
                            <b-col md="7">

                                <label for="castBefore" class="pt-1">Cast before</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-datepicker
                                        id="castBefore"
                                        name="castBefore"
                                        v-model="form.castBefore"
                                        :min="new Date()"
                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                        locale="us"
                                    />
                                </div>
                            </b-col>
                        </b-row>

                        <div>
                            <label for="roleType" class="pt-1">Character prototype illustration</label>
                            <b-form-file
                                v-model="form.newCover"
                                accept="image/*"
                            />
    
                            <div v-if="roleCover">
                                <div class="qf-cover-preview pt-1">
                                    <b-img :src="roleCover"></b-img>
                                </div>
                                <b-button class="my-1" size="sm" variant="success" @click="removeImage">
                                    Remove Image
                                </b-button>
                            </div>
                        </div>

                        <label for="synopsis" class="pt-1">Synopsis</label>
                        <div class="d-flex justify-content-lg-between align-items-center">
                            <b-form-textarea
                                id="synopsis"
                                name="synopsis"
                                v-model="form.synopsis"
                                placeholder="Short background "
                                rows="3"
                            />
                        </div>
                    </b-form-group>

                    <b-form-group label="Physical appearance" class="pb-1">
                        <b-row>
                            <b-col>
                                <label for="roleEthnicity" class="pt-1">Ethnicity</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-select
                                        id="roleEthnicity"
                                        name="roleEthnicity"
                                        v-model="form.ethnicity"
                                        :options="formOptions.ethnicities"
                                    >
                                        <template #first>
                                            <b-form-select-option value="null" disabled>Pick one</b-form-select-option>
                                        </template>
                                    </b-form-select>

                                </div>
                            </b-col>
                            <b-col>
                                <label for="hairColor" class="pt-1">Hair Color</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-select
                                        id="hairColor"
                                        name="hairColor"
                                        v-model="form.hairColor"
                                        :options="formOptions.hairColors"
                                    >
                                        <template #first>
                                            <b-form-select-option value="null" disabled>Pick one</b-form-select-option>
                                        </template>
                                    </b-form-select>

                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label for="eyeColor" class="pt-1">Eye Color</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-select
                                        id="eyeColor"
                                        name="eyeColor"
                                        v-model="form.eyeColor"
                                        :options="formOptions.eyeColors"
                                    >
                                        <template #first>
                                            <b-form-select-option value="null" disabled>Pick one</b-form-select-option>
                                        </template>
                                    </b-form-select>

                                </div>
                            </b-col>
                            <b-col>
                                <label for="bodyType" class="pt-1">Body type</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-select
                                        id="bodyType"
                                        name="bodyType"
                                        v-model="form.bodyType"
                                        :options="formOptions.bodyTypes"
                                    >
                                        <template #first>
                                            <b-form-select-option value="null" disabled>Pick one</b-form-select-option>
                                        </template>
                                    </b-form-select>

                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label for="ageMin" class="pt-1">Min Age</label>
                                <b-form-input name="ageMin"
                                              id="ageMin"
                                              v-model="form.ageMin"
                                              placeholder="Age in years"
                                              type="number"
                                />
                            </b-col>
                            <b-col>
                                <label for="ageMax" class="pt-1">Max Age</label>
                                <b-form-input name="ageMax"
                                              id="ageMax"
                                              v-model="form.ageMax"
                                              placeholder="Age in years"
                                              type="number"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label for="heightMin" class="pt-1">Min Height</label>
                                <b-form-input name="heightMin"
                                              id="heightMin"
                                              v-model="form.heightMin"
                                              placeholder="Height in cm"
                                              type="number"
                                />
                            </b-col>
                            <b-col>
                                <label for="heightMax" class="pt-1">Max Height</label>
                                <b-form-input name="heightMax"
                                              id="heightMax"
                                              v-model="form.heightMax"
                                              placeholder="Height in cm"
                                              type="number"
                                />
                            </b-col>
                        </b-row>
                    </b-form-group>



                    <b-form-group label="Required abilities" class="pb-1">
                        <label for="roleSkills" class="pt-1">Skills</label>
                        <Select2
                            id="roleSkills"
                            ref="roleSkillPicker"
                            class="mb-1"
                            v-model="form.skills"
                            :options="remapOptions(formOptions.skills)"
                            :settings="{
                                multiple: true,
                                //templateResult: companyPreview,
                                //templateSelection: selectionPreview,
                                //escapeMarkup: function(stuff){return stuff}
                             }"
                        />

                        <div class="qf-badge-group" v-if="false">
                            <b-badge v-for="(oneSkill,index) in form.skills"
                                     :key="index"
                                     variant="primary"
                                     @click="removePick(index, 'skills')"

                            >{{ badgeText(oneSkill, 'skills') }}
                                <feather-icon icon="XIcon" size="21"/>
                            </b-badge>
                        </div>

                        <label for="roleLanguages" class="pt-1">Languages</label>
                        <Select2
                            id="roleLanguages"
                            ref="roleLanguagePicker"
                            class="mb-1"
                            v-model="form.languages"
                            :options="remapOptions(formOptions.languages)"
                            :settings="{
                                multiple: true,
                                //templateResult: companyPreview,
                                //templateSelection: selectionPreview,
                                //escapeMarkup: function(stuff){return stuff}
                             }"
                        />

                        <div class="qf-badge-group" v-if="false">
                            <b-badge v-for="(oneLang,index) in form.languages"
                                     :key="index"
                                     variant="primary"
                                     @click="removePick(index, 'languages')"

                            >{{ badgeText(oneLang, 'languages') }}
                                <feather-icon icon="XIcon" size="21"/>
                            </b-badge>
                        </div>
                    </b-form-group>
                </validation-observer>

                <b-button variant="primary" class="mb-1 w-100" @click="submitRole()">{{ saveLabel }}</b-button>
                <b-button
                    variant="outline-primary"
                    class="mb-1 w-100"
                    @click="cancelModal()"
                >CANCEL
                </b-button>

                <hr v-if="false" class="qf-separator mb-2" />
                <b-button v-if="false" variant="danger" class="mb-1 w-100" @click="deleteRole()">DELETE  ROLE</b-button>

            </div>
        </div>

    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required, email,} from '@validations';
import {
    //BForm,
    //BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormFile,
    //BFormCheckbox,
    BButton,
    BBadge,
    BFormTextarea,
    BFormDatepicker,
    //BAvatar,
    BImg,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Parse from 'parse';
import store from "@/store";

import Select2 from 'v-select2-component';

export default {
    name: "ModalEditCompany",
    components: {
        //BForm,
        //BFormFile,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        BFormDatepicker,
        BFormFile,
        //BFormCheckbox,
        BButton,
        BBadge,
        BFormTextarea,
        ValidationProvider,
        ValidationObserver,
        //BAvatar,
        BImg,
        Select2
    },
    data() {
        return {
            required,
            email,
            formOptions: {
                skills: [],
                bodyTypes: [],
                roleTypes: [],
                hairColors: [],
                eyeColors: [],
                languages: [],
                genders: [],
            },
            form: {
                name: '',
                publicCasting: false,
                proCasting: false,
                synopsis: '',
                ageMax: null,
                ageMin: null,
                heightMax: null,
                heightMin: null,
                castBefore: null,
                newCover: null,

                roleType: '',
                hairColor: '',
                eyeColor: '',
                bodyType: '',
                ethnicity: '',
                gender: '',

                selectedLang: '',
                languages: [],

                selectedSkill: '',
                skills: [],

            },
            previewImgSrc: false,
            newRoleCover: null,
            prodId: null,
            roleId: 0,
            imgToBeRemoved: false,
        }
    },

    mounted() {
        this.formOptions.skills = store.getters['dict/roleSkills'];
        this.formOptions.bodyTypes = store.getters['dict/bodyTypes'];
        this.formOptions.roleTypes = store.getters['dict/roleTypes'];
        this.formOptions.ethnicities = store.getters['dict/ethnicities'];
        this.formOptions.hairColors = store.getters['dict/hairColors'];
        this.formOptions.eyeColors = store.getters['dict/eyeColors'];
        this.formOptions.languages = store.getters['dict/languages'];
        this.formOptions.genders = store.getters['dict/genders'];
        this.updateFormBasics();
        this.previewImgSrc = this.roleCoverUrl;
    },
    computed: {
        roleToEdit() {
            return store.getters['app/currentRole'];
        },
        curProduction() {
            return store.getters['app/currentProduction'];
        },
        saveLabel() {
            if (this.roleToEdit.id) {
                return "SAVE CHANGES";
            } else {
                return "CREATE ROLE"
            }
        },
        roleCover() {
            if (this.form.newCover) {
                let selectedFile = this.form.newCover;
                return URL.createObjectURL(selectedFile);
            } else if (this.previewImgSrc) {
                return this.previewImgSrc;
            } else {
                return false;
            }
        },
        roleCoverUrl() {
            let roleImg = false;
            if (this.roleToEdit && this.roleToEdit.attributes.characterIllustration) {
                roleImg = this.roleToEdit.attributes.characterIllustration._url;
            }
            return roleImg;
        },

    },
    methods: {
        remapOptions(options){
            if(options){
              options.forEach(function(oneOption, index){
                options[index] = {id: oneOption.value, value: oneOption.value, text: oneOption.text}
              });
            }
            return options;
        },
        resetForm(){
            this.roleId = null;
            this.form = {
                    name: '',
                    publicCasting: false,
                    proCasting: false,
                    synopsis: '',
                    ageMax: null,
                    ageMin: null,
                    heightMax: null,
                    heightMin: null,
                    castBefore: null,
                    newCover: null,

                    roleType: '',
                    hairColor: '',
                    eyeColor: '',
                    bodyType: '',
                    ethnicity: '',
                    gender: '',

                    selectedLang: '',
                    languages: [],

                    selectedSkill: '',
                    skills: [],

                };
                this.previewImgSrc= false;
                this.newRoleCover= null;
        },
        updateFormBasics() {

            if (this.roleToEdit && this.roleToEdit.attributes) {
                this.form = JSON.parse(JSON.stringify(this.roleToEdit.attributes));
                this.roleId = this.roleToEdit.id;
                this.previewImgSrc = this.roleCoverUrl;
            } else {
                this.resetForm();
            }

            let currentUser = Parse.User.current();
            if (currentUser) {
                this.currentUser = currentUser;
                this.curUserId = currentUser.id;
            }
        },


        badgeText(genreKey, field) {
            let entry = this.formOptions[field].find(o => o.value === genreKey);
            if (entry) {
                return entry.text;
            } else {
                return '';
            }
        },
        selectHandler(selection, field) {
            if (typeof (this.form[field]) == 'undefined') {
                this.form[field] = [];
            }
            //debugger;
            //console.log("Selection: ", this.form.genreSelection);
            this.form[field].push(selection.value);
            //this.$refs[picker].inputValue = ''
        },
        removePick(index, field) {
            this.form[field].splice(index, 1);
            //console.log(this.form.genres);
        },
        cancelModal() {
            if (this.roleId) {
                this.$root.$emit('bv::toggle::collapse', 'cu-singleRole');
                this.$root.$emit('bv::toggle::collapse', 'cu-role');
            } else {
                this.$root.$emit('bv::toggle::collapse', 'cu-role');
            }

        },
        submitRole() {
            let self = this;
            this.$refs[`roleValidation` + this.roleId].validate().then(success => {
                if (success) {
                    self.saveRole();
                }
            }).catch(function (err) {
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Check all fields for valid data!',
                        icon: 'EditIcon',
                        variant: 'danger',
                    },
                });
                console.log('validation errors', err);
            });
        },


        removeImage() {
            this.imgToBeRemoved = true;
            //this.roleToEdit.unset('characterIllustration');
            this.previewImgSrc = null;
        },


        saveRole() {
            let self = this;
            let actingObj = Parse.Object.extend('ActingRole');
            let Role = new actingObj();
            let activeProduction = this.curProduction;

            if (this.roleId) {
                Role.set('objectId', this.roleId);
            } else {
                Role.set('heat', 0);
                const prodPointer = {"__type": "Pointer", "className": "Production", "objectId": activeProduction.id};
                Role.set('production', prodPointer);
            }

            if (this.newRoleCover) {
                let parseFile = new Parse.File(this.newRoleCover.name, this.newRoleCover, this.newRoleCover.type);
                Role.set('characterIllustration', parseFile)
            }

            Role.set('publicCasting', this.form.publicCasting);
            Role.set('proCasting', this.form.proCasting);

            Role.set('name', this.form.name);
            Role.set('synopsis', this.form.synopsis);
            Role.set('roleType', this.form.roleType);
            Role.set('gender', this.form.gender);

            if(this.form.priority) {
                Role.set('priority', parseInt(this.form.priority));
            }

            /* Requirements */
            Role.set('ethnicity', this.form.ethnicity);
            Role.set('hairColor', this.form.hairColor);
            Role.set('eyeColor', this.form.eyeColor);
            Role.set('bodyType', this.form.bodyType);

            Role.set('ageMax', parseInt(this.form.ageMax));
            Role.set('ageMin', parseInt(this.form.ageMin));
            Role.set('heightMax', parseInt(this.form.heightMax));
            Role.set('heightMin', parseInt(this.form.heightMin));

            if (this.form.castBefore) {
                Role.set('castBefore', new Date(this.form.castBefore));
            }

            Role.set('skills', this.form.skills);
            Role.set('languages', this.form.languages);
            if (this.imgToBeRemoved){
                Role.set('characterIllustration', null)
            }
            if (this.form.newCover) {
                //let coverImg = this.form.newCover.files[0];
                let coverImg = this.form.newCover;
                let parseFile = new Parse.File(coverImg.name, coverImg, coverImg.type);
                Role.set('characterIllustration', parseFile)
            }

            Role.save().then(function (newRole) {
                console.log("New role:", newRole);
                newRole.fetchWithInclude(['production', 'castingLocation','talentPick']).then(function (fullRole) {
                    self.imgToBeRemoved = false;
                    if (self.roleId) {
                        store.commit('app/UPDATE_ROLE', fullRole);
                    } else {
                        store.commit('app/ADD_ROLE', fullRole);
                    }
                    store.commit('app/SET_ROLE', fullRole);
                    self.previewImgSrc = self.roleCoverUrl;
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Role successfully saved!',
                            icon: 'EditIcon',
                            variant: 'success',
                        },
                    });
                });

                self.cancelModal();

            }).catch(function (err) {
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: err.message,
                        icon: 'CloseIcon',
                        variant: 'danger',
                    },
                });
                console.log("Save error: ", err.message);
            });

        },

    },

    watch: {
        roleToEdit: {
            handler() {
                this.updateFormBasics();
            }
        }
    }
}
</script>


<style scoped>
.qf-cover-preview {
    width: 30%;
}

.qf-cover-preview img {
    width: 100%;
}

[dir] .custom-switch.custom-switch-secondary .custom-control-input:checked ~ .custom-control-label::before {
    background: linear-gradient(140deg, #9a1330, #ee6927) !important;
}

</style>
