<template>
    <div class="cs-role-row-container py-05 border-top d-flex justify-content-between align-items-center qf-role-row">
        <div class="d-flex align-items-center cursor-pointer"  @click="navToRole(role)">
            <div class="qf-role-priority" >#{{role.attributes.priority}}</div>
            <div class="position-relative">
                <b-img class="roleImageSize" :src="characterIllustration(role)"></b-img>
                <div class="cs-pro-badge" v-if="role.attributes.proCasting">
                    <b-badge variant="gradient-red" v-b-tooltip="`Casting is restricted to Cineself Pro Talent`">PRO</b-badge>
                </div>
            </div>

            <div class="px-1">
                <div class="qf-title-badge">
                    <h5>{{role.attributes.name}}</h5>
                </div>
                <p class="mb-0" v-if="role.attributes.castBefore">Cast before: {{niceDate(role.attributes.castBefore)}}</p>
            </div>
        </div>


        <div class="justify-content-end d-flex" >
            <b-avatar v-for="(shortlistAct, index) in shortlistedActors[role.id]"
                      :key="index"
                      button
                      badge-variant=""
                      badge-top
                      badge-offset="-0.5em"
                      v-b-tooltip.hover
                      v-b-toggle.cu-viewProfile
                      @click="openShortlised(shortlistAct)"
                      :src="applicantAvatar(shortlistAct)"
                      :title="shortlistAct.attributes.firstName + ' ' + shortlistAct.attributes.lastName"
                      :text="initials(shortlistAct)"
                      class="pull-up ml-05"
                      size="40px">
                <template #badge>
                    <div v-if="role.attributes.talentPick && role.attributes.talentPick.id === shortlistAct.id" class="selected-mark">
                        <b-img :src="icons.crown" width="10" />
                    </div>
                    <div v-else class="shortlisted-mark">
                        <feather-icon icon="CheckIcon"/>
                    </div>
                </template>
            </b-avatar>
        </div>
        <div class="justify-content-end d-flex">
            <feather-icon
                v-b-toggle.cu-role
                @click="setRoleToEdit(role)"
                icon="EditIcon"
                size="20"
                class="align-text-center text-success mr-1"
            />
            <feather-icon
                v-b-modal.delete-role
                @click="setRoleToEdit(role)"
                icon="TrashIcon"
                size="20"
                class="align-text-center text-success"
            />
        </div>
    </div>


</template>

<script>
import moment from 'moment';
import store from "@/store";
import {
    BAvatar,
    BBadge,
    BImg,
    VBTooltip,
    VBToggle,


} from 'bootstrap-vue';

export default {
    name: "RoleRow.vue",
    components: {
        BAvatar,
        BBadge,
        BImg,
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
    },
    props: {
        role: {
            type: Object,
            required: true
        },
        shortlistedActors: {
            type: Array,
            required: true
        },
        activeProduction: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            icons: {
                crown: require('@/assets/images/icons/crown.svg')
            }
        }
    },
    methods: {
        navToRole(roleObj){
            store.commit('app/SET_ROLE', roleObj);
            this.$router.push({path: '/production/'+this.activeProduction.id+'/roles/'+roleObj.id + '/details'});
        },
        characterIllustration(role) {
            let characterImg = this.defaultLogo('png');
            if (role.attributes.characterIllustration) {
                characterImg = role.attributes.characterIllustration._url;
            }
            return characterImg;
        },
        niceDate(date) {
            return moment(date).format('DD MMM YYYY');
        },
        applicantAvatar(talent){
            if(talent && talent.attributes.avatar){
                return talent.attributes.avatar._url;
            }else{
                return "";
            }
        },
        initials(applicant) {
            return applicant.attributes.firstName.charAt(0) + applicant.attributes.lastName.charAt(0);
        },
        openShortlised(shortlistAct){
            store.commit('app/SET_TALENT', shortlistAct);
        },
        setRoleToEdit(oneRole) {
            let allRoles = store.getters['app/allRoles'];
            let fullRoleObj = allRoles.find(role => role.id === oneRole.id);
            store.commit('app/SET_ROLE', fullRoleObj);
        },
    },
}
</script>

<style lang="scss">

.cs-pro-badge {
    position: absolute;
    top: 0;
    right: -30px;
    scale: 0.75;
}

</style>
