<template>
    <div class="qf-snap-scroll">
        <div v-for="(myImages, index) in galSections" :key="index" :class="galSectionClasses(index)">
            <div v-for="(galImg, index) in myImages" :key="index" :class="'a'+(index+1)">
                <b-img :src="galImg.attributes.attachment._url"></b-img>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BImg
    } from 'bootstrap-vue';
    import Parse from "parse";
    import store from "@/store";

    export default {
        name: 'qfGallery',
        components: {
            BImg,
        },
        props:{
            images: Array,
            userId: String,
        },
        mounted() {
        },
        data(){
            return {
                allImages: [],
                galSections: [],
                sectionSize: 5,
                galModal: null
            }
        },
        computed: {
            profileData(){
                let activeTalent = store.getters['app/talentToPreview'];
                if (!activeTalent.id) {
                    activeTalent = {
                        lastName: null,
                        attributes: {
                            relatedMeta: {
                                attributes: {}
                            }
                        }
                    }
                }
                return activeTalent;
            }
        },
        methods:{
            galSectionClasses(sectionIndex){
                let totalSections = this.galSections.length / 5
                if(sectionIndex >= (totalSections-1) ){
                    return 'qf-gallery last';
                }else {
                    return 'qf-gallery';
                }
            },
            chunkArray(myArray, chunk_size){
                let index = 0;
                let arrayLength = myArray.length;
                let tempArray = [];

                for (index = 0; index < arrayLength; index += chunk_size) {
                    let myChunk = myArray.slice(index, index+chunk_size);
                    // Do something if you want with the group
                    tempArray.push(myChunk);
                }

                return tempArray;
            },
            getGallery(){
                let self = this;

                let Media = Parse.Object.extend('Media');
                let mediaQuery = new Parse.Query(Media);

                const userPointer = { "__type": "Pointer", "className": "_User", "objectId": this.profileData.id };
                mediaQuery.equalTo('mediaType', 'talentGallery');
                mediaQuery.equalTo('relatedUser', userPointer);
                mediaQuery.ascending('galleryOrder');
                mediaQuery.find().then(function(images){
                    self.allImages = images;
                    if(images.length > 0 ){
                        self.galSections = self.chunkArray(images, self.sectionSize);
                    }
                    else{
                        self.galSections = '';
                    }
                });
                
            },
        },
        watch: {
            profileData: {
                immediate: true,
                handler(newTalent){
                    if(newTalent) {
                        this.getGallery();
                    }
                }
            },
        },
        setup() {
            return {

            }
        }
    };
</script>

<style scoped>
    .qf-gallery {
        padding-right: 8px;
        box-sizing: content-box;
        min-width: 320px;
        width: 320px;
        height: 300px;
        display: grid;
        grid-template-columns: 3fr 3fr 2fr;
        grid-template-rows: 3fr 2fr 3fr;
        gap: 8px;
        grid-template-areas:
                "a1 a3 a3"
                "a2 a3 a3"
                "a2 a4 a5";
    }
    .qf-gallery ion-img{
        overflow: hidden;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .a1 { grid-area: a1; }
    .a2 { grid-area: a2; }
    .a3 { grid-area: a3; }
    .a4 { grid-area: a4; }
    .a5 { grid-area: a5; }
    
    .qf-gallery img {
        overflow: hidden;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        visibility: inherit;
    }
</style>
